header {
    @include adaptiv-vaule("padding-top", 45, 25, 1);
    @include adaptiv-vaule("padding-bottom", 45, 25, 1);
    position: relative;

    .circle {
        position: absolute;
        top: 0;
        left: 0;

        @include media ("<desktop") {
            display: none;
        }
    }

    .ellipse {
        position: absolute;
        top: 0;
        left: 0;
        width: 327px;
        height: 300px;
        background: rgba(238, 246, 254, 0.7);
        filter: blur(25px);
        z-index: -1;

        @include media ("<desktop") {
            display: none;
        }
    }

    .header_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        margin-right: 20px;
        @include adaptiv-vaule("width", 285, 140, 1);
        @include adaptiv-vaule("height", 47, 25, 1);
        position: relative;
    }

    .menu_container {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(2, 35, 51, 0.1);
        backdrop-filter: blur(2px);
    }

    .menu_container_active {
        display: block;
        z-index: 3;
    }

    .nav,
    .nav-menu {
        display: flex;
        align-items: center;
    }

    .nav {
        @include media ("<desktop") {
            position: fixed;
            right: -100%;
            top: 0;
            transition: all .3s ease;
            flex-direction: column;
            width: 50%;
            height: 100vh;
            align-items: flex-start;
            background-color: $white;
            padding: 90px 55px 50px 55px;
            overflow: auto;
            z-index: 4;

            .nav-menu {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        @include media ("<phone_max") {
            width: 75%;
        }
    }

    .menu_active {
        right: 0;
        transition: all .3s ease;
    }

    .menu-item {
        @include adaptiv-vaule("margin-left", 50, -30, 1);

        @include media ("<desktop") {
            margin: 0 0 45px 0;
        }
    }

    .menu_link {
        font-weight: 600;
        @include adaptiv-vaule("font-size", 18, 14, 1);
        line-height: 21px;
        color: $blue;
        white-space: nowrap;

        @include media ("<desktop") {
            font-size: 18px;
        }
    }

    .dropdown {
        position: relative;
        @include adaptiv-vaule("margin-left", 50, -30, 1);
        cursor: pointer;

        @include media ("<desktop") {
            margin: 0 0 45px 0;
        }

        &:hover {
            .dropdown-content {
                opacity: 1;
                visibility: visible;
                transition: all .3s ease;

                @include media ("<desktop") {
                    height: 100%;
                    margin-top: 15px;
                }
            }

            .dropbtn {

                img {
                    transform: rotateZ(180deg);
                    transition: all .3s ease;
                }
            }

            @include media ("<desktop") {
                margin-bottom: 15px;
            }
        }
    }

    .dropbtn {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 4;

        img {
            margin-left: 8px;
            transition: all .3s ease;
        }
    }

    .dropdown-content {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        background-color: $white;
        border-radius: 15px;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        padding: 40px 15px 0 15px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        z-index: 3;

        @include media ("<desktop") {
            position: static;
            height: 0;
            padding: 0;
            transform: translateX(0);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 103%;
            height: 102%;
            z-index: 1;
            border-radius: 15px;
            background: linear-gradient(180deg, rgba(0, 136, 204, 0) 0%, rgba(0, 136, 204, 1) 100%);

            @include media ("<desktop") {
                display: none;
            }
        }

        &::after {
            position: absolute;
            content: '';
            top: 1px;
            left: 2px;
            width: 100%;
            height: 100%;
            z-index: 1;
            border-radius: 15px;
            background-color: $white;
        }
    }

    .dropdown_link {
        font-weight: 600;
        font-size: 14px;
        color: #828282;
        margin-bottom: 15px;
        position: relative;
        z-index: 2;
    }

    .booking_btn {
        @include adaptiv-vaule("margin-left", 40, 0, 1);
        font-weight: 700;
        color: #FFFFFF;
        background-color: $blue;
        border-radius: 10px;
        padding: 15px;
        max-width: 175px;
        width: 100%;
        text-align: center;
        @include adaptiv-vaule("font-size", 18, 14, 1);

        @include media ("<desktop") {
            font-size: 18px;
            margin: 0;
        }

        @include media ("<phone") {
            max-width: 100%;
        }
    }

    .burger_wrap {
        display: none;
        width: 38px;
        height: 38px;
        background-color: $blue;
        border-radius: 6px;
        transition: all 0.4s ease;
        z-index: 5;
        position: relative;

        @include media ("<desktop") {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .header_burger {
        width: 20px;
        height: 16px;
        position: relative;
        cursor: pointer;


        &::after,
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background: $white;
            transition: all 0.4s ease;
            border-radius: 6px;
        }

        span {
            position: absolute;
            width: 50%;
            height: 2px;
            background: $white;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            transition: all 0.4s ease;
            opacity: 1;
            border-radius: 6px;
        }

        &::after {
            bottom: 0;
        }

        &::before {
            top: 0;
        }
    }

    .burger_active {
        transition: all 0.4s ease;
        background-color: transparent;

        .header_burger {
            &::after {
                transform: rotate(-45deg);
                bottom: 7px;
                background-color: $blue;
            }

            &::before {
                transform: rotate(45deg);
                top: 7px;
                background-color: $blue;
            }

            span {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}