.body_lock {
    overflow: hidden;
}

.container {
    max-width: 1330px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.blue_btn {
    display: inline-block;
    max-width: 175px;
    width: 100%;
    background: #0088CC;
    border-radius: 10px;
    color: $white;
    font-weight: 700;
    font-size: 18px;
    padding: 15px;
    text-align: center;
}

.ibg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
