body {
    font-family: 'SFProDisplay';
    background-color: #F9FDFF;
}

.main_section {
    @include adaptiv-vaule("padding-top", 50, 55, 1);
    @include adaptiv-vaule("padding-bottom", 280, 150, 1);
    position: relative;

    .lines {
        position: absolute;
        bottom: -50%;
        left: 0;

        @include media ("<desktop") {
            bottom: 10%;
        }

        @include media ("<phone_max") {
            bottom: 30%;
        }
    }

    .diamond {
        position: absolute;
        right: 0;
        bottom: -20%;
        z-index: 1;
    }

    .main_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @include media ("<desktop") {
            flex-direction: column;
        }
    }

    .main_text {
        width: 45%;
        margin-right: 30px;

        @include media ("<desktop") {
            width: 100%;
            margin: 0 0 80px 0;
        }
    }

    .tip {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 24, 20, 1);
        color: $black;
        margin-bottom: 45px;
        line-height: 29px;
        position: relative;
        padding-left: 20px;

        &::before {
            content: '';
            position: absolute;
            top: -7px;
            left: 0;
            width: 7px;
            height: 150%;
            background-color: $rose;
            border-radius: 5px;
        }
    }

    .main_title {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 64, 40, 1);
        line-height: 76px;
        color: $black;
        margin-bottom: 20px;
    }

    .main_descr {
        font-size: 18px;
        line-height: 21px;
        color: $black;
        margin-bottom: 40px;
        max-width: 430px;
    }

    .main_img {
        width: 49%;
        @include adaptiv-vaule("height", 750, 405, 1);
        position: relative;


        img {
            overflow: hidden;
            border-radius: 15px;
        }

        @include media ("<desktop") {
            width: 60%;
        }

        @include media ("<phone") {
            width: 100%;
        }
    }

    .blue_square {
        position: absolute;
        width: 183px;
        height: 183px;
        border-radius: 15px;
        background-color: $blue;
        right: -30px;
        top: 150px;
        @include adaptiv-vaule("right", -30, -10, 1);
        @include adaptiv-vaule("top", 150, -10, 1);
    }
}

.services {
    @include adaptiv-vaule("padding-top", 15, 50, 1);
    @include adaptiv-vaule("padding-bottom", 200, 85, 1);
    position: relative;

    .circle_left {
        position: absolute;
        left: 0;
        bottom: 18%;
    }

    .circle_right {
        position: absolute;
        right: 0;
        top: 18%;
    }

    .ellips_left {
        position: absolute;
        left: 0;
        bottom: 14%;
        background: rgba(238, 246, 254, 0.7);
        filter: blur(25px);
        width: 326px;
        height: 300px;
        z-index: -1;
    }

    .ellips_right {
        position: absolute;
        right: 0;
        top: 20%;
        background: rgba(238, 246, 254, 0.7);
        filter: blur(25px);
        width: 326px;
        height: 300px;
        z-index: -1;
    }

    .dots {
        position: absolute;
        right: 0;
        bottom: 50px;
    }

    .services_content {
        text-align: center;
        position: relative;
        z-index: 1;
    }

    .services_title {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 36, 32, 1);
        color: $gray;
        @include adaptiv-vaule("margin-bottom", 100, 35, 1);
    }

    .item_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @include media ("<desktop") {
            flex-wrap: wrap;
        }
    }

    .item {
        text-align: center;
        width: 33%;
        position: relative;

        @include media ("<desktop") {
            width: 45%;
        }

        @include media ("<tablet") {
            width: 100%;
        }
    }

    .item_link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .item_img {
        max-width: 441px;
        width: 100%;
        @include adaptiv-vaule("height", 343, 265, 1);
        margin: 0 auto;
        margin-bottom: 25px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
    }

    .item_title {
        font-weight: 700;
        font-size: 24px;
        color: $gray;
    }
}

.advantage {
    background-color: $white;
    position: relative;

    @include media ("<desktop") {
        padding-top: 15px;
    }

    .diamond {
        position: absolute;
        left: 0;
        bottom: -60%;
        z-index: 1;

        @include media ("<desktop") {
            bottom: -20%;
        }
    }

    .advantage_content {
        display: flex;
        justify-content: flex-end;
        position: relative;

        @include media ("<desktop") {
            flex-direction: column-reverse;
            align-items: center;
            width: calc(100% - 30px);
            margin: 0 auto;
        }
    }

    .advantage_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;

        @include media ("<desktop") {
            position: static;
            border-radius: 20px;
            overflow: hidden;
            max-width: 750px;
            width: 100%;
            height: auto;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .advantage_text {
        width: 40%;
        text-align: start;

        @include media ("<desktop") {
            width: 100%;
            text-align: center;
        }
    }

    .advantage_title {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 64, 36, 1);
        color: $gray;
        margin-bottom: 20px;
    }

    .advantage_descr {
        @include adaptiv-vaule("font-size", 18, 20, 1);
        line-height: 130%;
        color: $gray;
        max-width: 370px;
        @include adaptiv-vaule("margin-bottom", 125, 60, 1);

        @include media ("<desktop") {
            margin: 0 auto;
            @include adaptiv-vaule("margin-bottom", 125, 60, 1);
        }
    }

    .items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @include media ("<desktop") {
            justify-content: center;
        }
    }

    .item {
        width: 49%;
        margin-bottom: 150px;
        @include adaptiv-vaule("margin-bottom", 150, 60, 1);

        @include media ("<desktop") {
            text-align: center;
        }
    }

    .item_img {
        margin-left: 5px;
        margin-bottom: 25px;
        width: 78px;
        height: 78px;
        overflow: hidden;

        @include media ("<desktop") {
            margin: 0 auto;
            margin-bottom: 25px;
        }
    }
}

.lager {
    text-align: center;
    @include adaptiv-vaule("padding-top", 175, 230, 1);
    @include adaptiv-vaule("padding-bottom", 60, 130, 1);

    .tip {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 36, 24, 1);
        color: $black;
        @include adaptiv-vaule("margin-bottom", 65, 20, 1);
        display: inline-block;
    }

    .lager_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .hidden {
        display: none;

        @include media ("<tablet") {
            display: block;
        }
    }

    .visible {
        @include media ("<tablet") {
            display: none;
        }
    }

    .lager_img {
        position: relative;
        @include adaptiv-vaule("width", 507, 335, 1);
        @include adaptiv-vaule("height", 507, 335, 1);
        margin-right: 30px;

        @include media ("<tablet") {
            margin: 0 0 60px 0;
        }

        &::after,
        &::before {
            content: '';
            position: absolute;
            @include adaptiv-vaule("width", 507, 335, 1);
            @include adaptiv-vaule("height", 507, 335, 1);
            border-radius: 50%;
            overflow: hidden;
            z-index: -1;
        }

        &::after {
            bottom: -3px;
            right: -3px;
            background-color: $rose;
        }

        &::before {
            top: -3px;
            left: -3px;
            background-color: $blue;
        }

        img {
            border-radius: 50%;
            overflow: hidden;
        }
    }

    .lager_text {
        width: 48%;
        text-align: start;

        @include media ("<tablet") {
            width: 100%;
            text-align: center;
        }
    }

    .lager_title {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 38, 36, 1);
        color: $black;
        margin-bottom: 45px;
    }

    .lager_descr {
        @include adaptiv-vaule("font-size", 18, 20, 1);
        line-height: 140%;
        color: $black;
        max-width: 430px;
        margin-bottom: 55px;
    }

    .blue_btn {
        max-width: 350px;
    }
}

.reviews {
    @include adaptiv-vaule("padding-top", 175, 50, 1);
    @include adaptiv-vaule("padding-bottom", 315, 45, 1);
    position: relative;
    text-align: center;
    overflow: hidden;

    .lines {
        position: absolute;
        left: 0;
        top: -50px;
        z-index: -1;
    }

    .circle {
        position: absolute;
        background-color: #DDEEFE;
        @include adaptiv-vaule("width", 333, 145, 1);
        @include adaptiv-vaule("height", 333, 145, 1);
        border-radius: 50%;
        bottom: 0;
        left: 30%;

        @include media ("<desktop") {
            left: 85%;
            bottom: 300px;
        }

        @include media ("<phone") {
            bottom: 195px;
        }
    }

    .reviews_title {
        font-weight: 700;
        font-size: 36px;
        color: $black;
        margin-bottom: 100px;
        @include adaptiv-vaule("margin-bottom", 175, 80, 1);
    }

    .reviews_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media ("<desktop") {
            flex-direction: column-reverse;
            gap: 200px;
        }
    }

    .reviews_swiper {
        width: 30%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 70px;

        @include media ("<desktop") {
            width: 100%;
            height: 250px;
            padding: 0;
        }

        .swiper-button-prev {
            top: 25%;
            left: 10px;

            @include media ("<desktop") {
                left: 70%;
                top: 10%;
                transform: rotateZ(-90deg);
            }

            &::after {
                content: url(../images/reviews/arrow_prev.svg)
            }
        }

        .swiper-button-next {
            top: 60%;
            left: 10px;

            @include media ("<desktop") {
                left: 90%;
                top: 10%;
                transform: rotateZ(-90deg);
            }

            &::after {
                content: url(../images/reviews/arrow_next.svg)
            }
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: start;
    }

    .card_title {
        font-weight: 700;
        font-size: 48px;
        color: $black;
        margin-bottom: 20px;
    }

    .date {
        font-size: 18px;
        color: $black;
        margin-bottom: 20px;
        display: inline-block;
    }

    .card_descr {
        font-size: 18px;
        line-height: 150%;
        color: $black;
    }

    .button_wrap {
        width: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .reviews_img {
        width: 65%;
        margin-left: 30px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media ("<desktop") {
            width: 100%;
            margin: 0;
        }

        .reviews_img_first {
            z-index: 1;
            @include adaptiv-vaule("width", 510, 240, 1);
            @include adaptiv-vaule("height", 380, 175, 1);
        }

        .reviews_img_second {
            position: absolute;
            left: 0px;
            top: 50%;
            @include adaptiv-vaule("width", 140, 65, 1);
            @include adaptiv-vaule("height", 100, 50, 1);

            @include media ("<desktop") {
                top: 50%;
            }
        }

        .reviews_img_third {
            position: absolute;
            top: -150px;
            left: 0px;
            @include adaptiv-vaule("width", 320, 150, 1);
            @include adaptiv-vaule("height", 240, 110, 1);

            @include media ("<desktop") {
                top: -65px;
                left: 0;
            }
        }

        .reviews_img_fourth {
            position: absolute;
            z-index: 2;
            top: -40px;
            right: 130px;
            @include adaptiv-vaule("width", 160, 75, 1);
            @include adaptiv-vaule("height", 120, 55, 1);

            @include media ("<desktop") {
                right: 10px;
            }
        }

        .reviews_img_fifth {
            position: absolute;
            z-index: 2;
            bottom: -70px;
            right: 160px;
            @include adaptiv-vaule("width", 160, 75, 1);
            @include adaptiv-vaule("height", 120, 55, 1);

            @include media ("<desktop") {
                right: 20px;
                bottom: -30px;
            }
        }

        .reviews_img_sixth {
            position: absolute;
            left: 100px;
            bottom: -200px;
            @include adaptiv-vaule("width", 320, 150, 1);
            @include adaptiv-vaule("height", 240, 110, 1);


            @include media ("<desktop") {
                left: 35px;
                bottom: -100px;
            }
        }
    }
}

.contact {
    @include adaptiv-vaule("padding-top", 35, 50, 1);
    @include adaptiv-vaule("padding-bottom", 110, 60, 1);

    .contact_content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;

        @include media ("<desktop") {
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }

    .contact_title {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 36, 20, 1);
        color: $black;
        width: 35%;

        @include media ("<desktop") {
            width: 100%;
            text-align: center;
            margin-bottom: 35px;
        }

        @include media ("<phone_max") {
            text-align: start;
        }
    }

    .link {
        width: 30%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include media ("<desktop") {
            width: 45%;
            flex-direction: row;
            align-items: center;
        }

        @include media ("<phone_max") {
            width: 100%;
        }
    }

    .link_text {
        font-weight: 500;
        @include adaptiv-vaule("font-size", 32, 20, 1);
        color: $black;

    }

    .contact_img {
        margin-bottom: 40px;
        width: 50px;
        height: 50px;

        @include media ("<desktop") {
            margin: 0 20px 0 0;
        }
    }
}