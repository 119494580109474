@font-face {
    font-family: "SFProDisplay";
    src: url("../fonts/SFProDisplay-Regular.ttf");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "SFProDisplay";
    src: url("../fonts/SFProDisplay-Medium.ttf");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("../fonts/SFProDisplay-Bold.ttf");
    font-style: normal;
    font-weight: 700;
}