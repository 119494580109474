.home_bleachingh {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);
    position: relative;
    z-index: 2;

    .main_img {
        width: 100%;
        @include adaptiv-vaule("height", 620, 325, 1);
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .main_descr {
        font-weight: 400;
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: $gray;
    }
}

.price_section {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);

    .service_title_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;

        @include media ("<desktop") {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .title {
        max-width: 880px;
        width: 100%;
        margin-right: 30px;
        font-weight: 700;
        @include adaptiv-vaule("font-size", 32, 26, 1);
        color: $gray;

        @include media ("<desktop") {
            margin-bottom: 25px;
        }
    }

    .price {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 48, 36, 1);
        color: #0088CC;
    }

    .tip_wrap {
        margin-bottom: 35px;
    }

    .tip {
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: $gray;
        max-width: 840px;
        width: 100%;
    }

    .jewellery_service {
        @include adaptiv-vaule("padding-bottom", 80, 55, 1);
        border-bottom: 1px dashed #9F9F9F;

        &:not(:last-child) {
            margin-bottom: 60px;
        }
    }

    .service_list_wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .service_list {
        width: 47%;

        @include media ("<tablet") {
            width: 100%;

            &:first-child {
                margin-bottom: 35px;
            }
        }
    }

    .list_item {
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        color: $gray;
        position: relative;
        padding-left: 20px;

        &::before {
            content: '';
            position: absolute;
            top: 15px;
            left: 0;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $gray;
        }
    }
}

.bleaching_descr {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);

    .text_one,
    .text_two,
    .text_three {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include adaptiv-vaule("margin-bottom", 100, 90, 1);
        gap: 20px;

        @include media ("<desktop") {
            flex-direction: column;
        }

        .text_wrap {
            width: 55%;

            @include media ("<desktop") {
                width: 100%;
            }
        }

        .img_wrap{
            width: 40%;
        }

        .text_img {
            width: 100%;
            @include adaptiv-vaule("height", 800, 495, 1);
            border-radius: 25px;
            overflow: hidden;

            @include media ("<desktop") {
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .visible {
        @include media ("<desktop") {
            display: none
        }
    }

    .hidden {
        display: none;

        @include media ("<desktop") {
            display: block
        }
    }

    .text_one {
        @include media ("<desktop") {
            flex-direction: column-reverse;
        }
    }

    .text_two {

        .text_img {
            @include adaptiv-vaule("height", 540, 335, 1);
        }
    }

    .text_three {
        margin-bottom: 0;

        @include media ("<desktop") {
            flex-direction: column-reverse;
        }

        .img_wrap {
            width: 40%;

            @include media ("<desktop") {
                width: 100%;
            }
        }

        .text_img {
            width: 100%;
            @include adaptiv-vaule("height", 615, 380, 1);
        }

        .first {
            @include adaptiv-vaule("margin-bottom", 60, 35, 1);
        }
    }

    .text_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        color: $gray;
        margin-bottom: 25px
    }

    .text_descr {
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: $gray;
    }
}