.post_main {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);
    position: relative;
    z-index: 2;

    .main_img {
        width: 100%;
        @include adaptiv-vaule("height", 620, 325, 1);
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.post_text_section {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);

    .block_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 45px;
        gap: 20px;

        @include media ("<desktop") {
            flex-direction: column;
        }
    }

    .revert{
        flex-direction: row-reverse;

        @include media ("<desktop") {
            flex-direction: column;
        }
    }

    .block_img {
        width: 44%;
        @include adaptiv-vaule("height", 675, 400, 1);
        border-radius: 25px;
        overflow: hidden;

        @include media ("<desktop") {
            max-width: 450px;
            width: 100%;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text_block {
        width: 54%;

        @include media ("<desktop") {
            width: 100%
        }
    }

    .text {
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: $gray;
    }

    .description{
        margin-bottom: 45px;
    }
}