.jewellery_main {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);

    .jewellery_main_img {
        width: 100%;
        @include adaptiv-vaule("height", 620, 325, 1);
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 40px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .jewellery_main_text {
        text-align: center;
    }

    .main_title {
        font-weight: 700;
        font-size: 36px;
        color: #4F4F4F;
        @include adaptiv-vaule("margin-bottom", 60, 35, 1);
    }

    .title_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include adaptiv-vaule("margin-bottom", 40, 35, 1);

        @include media ("<desktop") {
            flex-direction: column;
        }
    }

    .text_img {
        border-radius: 25px;
        overflow: hidden;
        max-width: 585px;
        @include adaptiv-vaule("height", 345, 200, 1);
        margin-right: 30px;

        @include media ("<desktop") {
            margin: 0 0 35px 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .title_text {
        width: 50%;
        text-align: start;

        @include media ("<desktop") {
            width: 100%;
        }
    }

    .subtitle {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 32, 24, 1);
        line-height: 150%;
        color: #4F4F4F;
        @include adaptiv-vaule("margin-bottom", 45, 35, 1);
    }

    .tip {
        font-weight: 400;
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: #4F4F4F;
    }

    .jewellery_text {
        text-align: start;
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: #4F4F4F;
    }
}

.subtext {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);

    .subtext_content {
        text-align: center;
    }

    .text_title {
        font-weight: 700;
        font-size: 36px;
        color: #4F4F4F;
        @include adaptiv-vaule("margin-bottom", 55, 35, 1);
    }

    .subtext_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media ("<desktop") {
            flex-direction: column-reverse;
        }
    }

    .jewellery_text {
        width: 55%;
        text-align: start;
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: #4F4F4F;
        margin-right: 30px;

        @include media ("<desktop") {
            margin: 0;
            width: 100%
        }
    }

    .subtext_img {
        @include adaptiv-vaule("height", 630, 340, 1);
        width: 43%;
        border-radius: 25px;
        overflow: hidden;

        @include media ("<desktop") {
            margin: 0 0 35px 0;
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.jewellery_services {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);

    .jewellery_services_title {
        text-align: center;
        font-weight: 700;
        font-size: 36px;
        color: $gray;
        @include adaptiv-vaule("margin-bottom", 100, 60, 1);
    }

    .service_title_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;

        @include media ("<desktop") {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .title {
        max-width: 880px;
        width: 100%;
        margin-right: 30px;
        font-weight: 700;
        @include adaptiv-vaule("font-size", 32, 26, 1);
        color: $gray;

        @include media ("<desktop") {
            margin-bottom: 25px;
        }
    }

    .price {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 48, 36, 1);
        color: #0088CC;
    }

    .tip_wrap {
        margin-bottom: 35px;
    }

    .tip {
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: $gray;
        max-width: 840px;
        width: 100%;
    }

    .jewellery_service {
        @include adaptiv-vaule("padding-bottom", 80, 55, 1);
        border-bottom: 1px dashed #9F9F9F;

        &:not(:last-child) {
            margin-bottom: 60px;
        }
    }

    .service_list_wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .service_list {
        width: 47%;

        @include media ("<tablet") {
            width: 100%;

            &:first-child {
                margin-bottom: 35px;
            }
        }
    }

    .list_item {
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        color: $gray;
        position: relative;
        padding-left: 20px;

        &::before {
            content: '';
            position: absolute;
            top: 15px;
            left: 0;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $gray;
        }
    }
}