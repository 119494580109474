.contact_main_section {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);
    position: relative;
    z-index: 2;

    .contact_swiper {
        @include adaptiv-vaule("height", 620, 325, 1);
        width: 100%;
        padding-right: 50px;
        margin-left: 0;

        @include media ("<desktop") {
            padding: 0;
        }

        @include media ("<tablet") {
            margin-bottom: 35px;
        }

        .swiper-pagination {
            @include media ("<desktop") {
                display: none;
            }
        }

        .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            background: #D9D9D9;
            border-radius: 50%;
            overflow: hidden;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: $rose;
        }

        .swiper-button-prev {
            top: 35%;
            left: auto;
            right: 5px;

            &::after {
                content: url(../images/contact/arrow_prev.svg);
            }

            @include media ("<desktop") {
                display: none;
            }
        }

        .swiper-button-next {
            top: 60%;
            right: 5px;

            &::after {
                content: url(../images/contact/arrow_next.svg);
            }

            @include media ("<desktop") {
                display: none;
            }
        }
    }

    .card {
        border-radius: 25px;
        overflow: hidden;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .main_text {
        font-size: 20px;
        line-height: 150%;
        color: $gray;
        display: none;

        @include media ("<tablet") {
            display: block;
        }
    }
}

.welcome {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 90, 40, 1);

    .tip {
        text-align: center;
        font-weight: 700;
        @include adaptiv-vaule("font-size", 36, 26, 1);
        color: $gray;
        margin-bottom: 25px;
    }

    .welcome_text_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media ("<desktop") {
            flex-direction: column;
        }
    }

    .title_wrap {
        width: 45%;

        @include media ("<desktop") {
            width: 100%;
        }
    }

    .welcome_title {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 85, 48, 1);
        line-height: 100 / 85 * 100%;
        color: $gray;
        @include adaptiv-vaule("margin-bottom", 15, 5, 1);
    }

    .welcome_descr {
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 29px;
        color: $gray;
    }

    .subtext {
        width: 50%;

        @include media ("<desktop") {
            width: 100%;
        }
    }

    .subtext_title {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 32, 26, 1);
        line-height: 38px;
        color: $gray;
    }

    .subtext_decr {
        @include adaptiv-vaule("font-size", 24, 20, 1);
        line-height: 150%;
        color: $gray;
    }
}

.contact_form {
    @include adaptiv-vaule("padding-top", 55, 45, 1);
    @include adaptiv-vaule("padding-bottom", 25, 40, 1);

    .content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background: #F9FDFF;
        border: 2px dashed #0088CC;
        border-radius: 25px;
        @include adaptiv-vaule("padding-top", 115, 50, 1);
        @include adaptiv-vaule("padding-bottom", 45, 40, 1);
        @include adaptiv-vaule("padding-right", 50, 25, 1);
        @include adaptiv-vaule("padding-left", 50, 25, 1);
        gap: 30px;

        @include media ("<desktop") {
            flex-direction: column;
        }
    }

    .form_text {
        max-width: 360px;
        width: 100%;

        @include media ("<desktop") {
            max-width: 100%;
        }
    }

    .text_title {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 32, 26, 1);
        color: $gray;
        @include adaptiv-vaule("margin-bottom", 30, 25, 1);
    }

    .schedule_wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include adaptiv-vaule("margin-bottom", 80, 40, 1);
        max-width: 360px;
        width: 100%;
    }

    .schedule {
        &:not(:last-child) {
            @include adaptiv-vaule("margin-bottom", 35, 20, 1);
        }

        @include adaptiv-vaule("padding-top", 15, 7, 1);
        @include adaptiv-vaule("padding-bottom", 15, 7, 1);
        padding-left: 25px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include media ("<phone") {
            flex-direction: column;
            align-items: flex-start;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 7px;
            height: 100%;
            background: $rose;
            border-radius: 5px;
        }
    }

    .schedule_item {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 24, 20, 1);
        color: $black;

        &:first-child {
            @include media ("<phone") {
                margin-bottom: 5px;
            }
        }
    }

    .link {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .phone {
        @include adaptiv-vaule("margin-bottom", 65, 40, 1);
    }

    .link_text {
        font-weight: 500;
        @include adaptiv-vaule("font-size", 32, 20, 1);
        color: $black;

    }

    .contact_img {
        margin-bottom: 40px;
        width: 50px;
        height: 50px;

        @include media ("<desktop") {
            margin: 0 0 20px 0;
        }
    }

    form {
        width: 60%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include media ("<desktop") {
            width: 100%;
        }
    }

    .input_wrap {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        &:not(:last-child) {
            @include adaptiv-vaule("margin-bottom", 40, 25, 1);
        }
    }

    .data_name {
        font-weight: 700;
        @include adaptiv-vaule("font-size", 32, 20, 1);
        color: $gray;
        @include adaptiv-vaule("margin-bottom", 20, 10, 1);
    }

    .data,
    .area_text {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #D6D6D6;
        border-radius: 10px;
        padding: 15px 30px;
        @include adaptiv-vaule("font-size", 28, 20, 1);
        color: $black;
        transition: all .3s ease;

        @include media ("<phone") {
            padding: 15px 20px;
        }

        &::placeholder {
            @include adaptiv-vaule("font-size", 28, 20, 1);
            color: #C0B5B5;
        }

        &:focus {
            border: 1px solid $rose;
        }
    }

    .area_text {
        height: 280px;
    }

    .tip {
        font-size: 16px;
        display: inline-block;
        color: $black;
        @include adaptiv-vaule("margin-bottom", 50, 20, 1);
    }

    .blue_btn {
        border: none;
    }
}